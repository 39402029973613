"use client";

import { useEffect, useRef } from "react";
import { TextLink } from "@/components/edges/ui/TextLink/TextLink";
export default function NotFound() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;
    const setCanvasSize = () => {
      const rect = canvas.getBoundingClientRect();
      canvas.width = rect.width * window.devicePixelRatio;
      canvas.height = rect.height * window.devicePixelRatio;
      ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
    };
    setCanvasSize();
    window.addEventListener("resize", setCanvasSize);

    // Create a grid-based pattern with subtle movement
    const gridSize = 40;
    const points: Array<{
      x: number;
      y: number;
      opacity: number;
      phase: number;
    }> = [];

    // Initialize grid points
    const initPoints = () => {
      const rect = canvas.getBoundingClientRect();
      const cols = Math.floor(rect.width / gridSize);
      const rows = Math.floor(rect.height / gridSize);
      for (let i = 0; i <= cols; i++) {
        for (let j = 0; j <= rows; j++) {
          points.push({
            x: i * gridSize,
            y: j * gridSize,
            opacity: 0.1 + Math.random() * 0.1,
            phase: Math.random() * Math.PI * 2
          });
        }
      }
    };
    initPoints();

    // Animation loop
    let animationFrame: number;
    const animate = () => {
      const rect = canvas.getBoundingClientRect();
      ctx.clearRect(0, 0, rect.width, rect.height);

      // Update and draw points
      points.forEach(point => {
        point.phase += 0.02;
        const yOffset = Math.sin(point.phase) * 2;

        // Draw vertical lines
        ctx.beginPath();
        ctx.moveTo(point.x, point.y + yOffset);
        ctx.lineTo(point.x, point.y + yOffset + 10);
        ctx.strokeStyle = `rgba(59, 130, 246, ${point.opacity * 0.5})`;
        ctx.stroke();

        // Draw connecting lines
        points.forEach(otherPoint => {
          const distance = Math.hypot(point.x - otherPoint.x, point.y - otherPoint.y);
          if (distance < gridSize * 1.5) {
            ctx.beginPath();
            ctx.moveTo(point.x, point.y + yOffset);
            ctx.lineTo(otherPoint.x, otherPoint.y + Math.sin(otherPoint.phase) * 2);
            ctx.strokeStyle = `rgba(59, 130, 246, ${0.03 * (1 - distance / (gridSize * 1.5))})`;
            ctx.stroke();
          }
        });
      });
      animationFrame = requestAnimationFrame(animate);
    };
    animate();
    return () => {
      window.removeEventListener("resize", setCanvasSize);
      cancelAnimationFrame(animationFrame);
    };
  }, []);
  return <div className="relative flex min-h-screen overflow-hidden bg-background-muted pb-36 pt-36 md:items-center md:justify-center md:pt-0" data-sentry-component="NotFound" data-sentry-source-file="not-found.tsx">
      <canvas ref={canvasRef} className="absolute inset-0 h-full w-full" style={{
      opacity: 0.4
    }} />

      <div className="relative z-10">
        <main className="mx-auto max-w-2xl px-4 text-center">
          <h1 className="mb-4 text-8xl font-bold text-brand-primary">404</h1>
          <h2 className="mb-4 text-2xl font-medium text-gray-900">
            Page Not Found
          </h2>
          <p className="mb-8 text-base text-gray-600">
            The requested resource could not be found. Please verify the URL or
            navigate back to the homepage.
          </p>
          <TextLink href="/" variant="primary" data-sentry-element="TextLink" data-sentry-source-file="not-found.tsx">
            Return to Homepage
          </TextLink>
        </main>
      </div>
    </div>;
}