import Link from "next/link";
import { ReactNode } from "react";
interface TextLinkProps {
  href: string;
  children: ReactNode;
  className?: string;
  external?: boolean;
  title?: string;
  variant?: "default" | "primary";
}
export const TextLink = ({
  href,
  children,
  className = "",
  external = false,
  title,
  variant = "default"
}: TextLinkProps) => {
  const externalProps = external ? {
    target: "_blank",
    rel: "noopener noreferrer"
  } : {};
  const baseClasses = "hover:underline hover:decoration-1 hover:underline-offset-4";
  const variantClasses = {
    default: "!text-action-primary hover:text-action-primary-hover",
    primary: "text-blue-600 hover:text-blue-700"
  };
  return <Link href={href} className={`${baseClasses} ${variantClasses[variant]} ${className}`} {...externalProps} title={title} role="link" data-sentry-element="Link" data-sentry-component="TextLink" data-sentry-source-file="TextLink.tsx">
      {children}
    </Link>;
};
export default TextLink;